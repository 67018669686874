import { useHistory } from "react-router-dom"; // Import useHistory
import React from "react";

const Loader = () => {
    const history = useHistory(); // Initialize navigation

    return (
        <div className="loader-body" onClick={() => history.push("/valentines")} style={{ cursor: "pointer" }}>
            <div className="loader">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <span className="terra"></span>
        </div>
    );
};

export default Loader;
