import React, { useState, useEffect } from "react";
import "./Valentines.css";

const images = [
    "/images/timothee.jpg",
    "/images/js.jpg",
    "/images/tom.jpg",
    "/images/chris.jpg",
    "/images/top.jpg",
    "/images/gongyoo.jpg",
];

const Valentines = () => {
    const [slots, setSlots] = useState([
        images[Math.floor(Math.random() * images.length)],
        images[Math.floor(Math.random() * images.length)],
        images[Math.floor(Math.random() * images.length)],
    ]);
    const [message, setMessage] = useState(""); // Restore message state
    const [spinning, setSpinning] = useState(false);
    const [spinCount, setSpinCount] = useState(0);
    const [pullingHandle, setPullingHandle] = useState(false);
    const [winner, setWinner] = useState(false); // Controls the winner pop-up
    const [showConfetti, setShowConfetti] = useState(false); // Controls confetti animation

    useEffect(() => {
        let interval;
        if (spinning) {
            interval = setInterval(() => {
                setSlots([
                    images[Math.floor(Math.random() * images.length)],
                    images[Math.floor(Math.random() * images.length)],
                    images[Math.floor(Math.random() * images.length)],
                ]);
            }, 100); // Change images every 100ms while spinning
        }

        return () => clearInterval(interval); // Cleanup interval when spin stops
    }, [spinning]);

    const losingMessagesOriginal = [
        "❌ Maybe if you become a player! Gong yoo would match with you.",
        "😢 Lonelyyy I am so lonelyyy!",
        "💔 No match... Spin again!",
        "🙃 Oops! Try once more!"
    ];
    
    // Shuffle function (Fisher-Yates algorithm)
    const shuffleArray = (array) => {
        let shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };
    
    const [losingMessages, setLosingMessages] = useState(shuffleArray(losingMessagesOriginal));
    const [messageIndex, setMessageIndex] = useState(0);
    
    const spin = () => {
        if (spinning) return; // Prevent multiple spins
        setSpinning(true);
        setPullingHandle(true);
        setWinner(false); // Hide winner pop-up on new spin
        setMessage(""); // Reset message
        setShowConfetti(false); // Hide confetti
    
        setTimeout(() => {
            setPullingHandle(false); // Reset lever animation
        }, 500);
    
        setTimeout(() => {
            let finalSlots;
            if ((spinCount + 1) % 5 === 0) {
                finalSlots = Array(3).fill("/images/tom.jpg"); // Win on the 5th spin
                setWinner(true); // Show pop-up for win
                setShowConfetti(true); // Show confetti
                setTimeout(() => setShowConfetti(false), 3000); // Hide confetti after 3 seconds
                setMessage("🎉 It's TOM CRUISEEE!"); // Display success message
            } else {
                // Randomly generate 3 slot images
                finalSlots = [
                    images[Math.floor(Math.random() * images.length)],
                    images[Math.floor(Math.random() * images.length)],
                    images[Math.floor(Math.random() * images.length)],
                ];
    
                // If all 3 are the same and not "tom.jpg", change one
                if (finalSlots[0] === finalSlots[1] && finalSlots[1] === finalSlots[2] && finalSlots[0] !== "/images/tom.jpg") {
                    finalSlots[2] = images.find(img => img !== finalSlots[0]); // Pick a different image
                }
    
                // Display messages in order
                setMessage(losingMessages[messageIndex]);
    
                // Update the index to cycle through messages
                setMessageIndex((prevIndex) => {
                    if (prevIndex + 1 >= losingMessages.length) {
                        setLosingMessages(shuffleArray(losingMessagesOriginal)); // Reshuffle when all messages are shown
                        return 0;
                    }
                    return prevIndex + 1;
                });
            }
    
            setSlots(finalSlots);
            setSpinning(false);
            setSpinCount(spinCount + 1);
        }, 2000);
    };
    

    return (
        <div className="valentines-container">
            <h1 className="slot-header">❤️ Who's your VALENTINES ❤️</h1>
            <div className="slot-machine">
                <div className={`lever-container ${pullingHandle ? "pulled" : ""}`} onClick={spin}>
                    <div className="lever"></div> {/* Brown stick */}
                    <div className="lever-ball"></div> {/* Red ball at the top */}
                </div>
                <div className="slots">
                    {slots.map((img, index) => (
                        <div key={index} className="slot">
                            <img src={img} alt="slot item" className={spinning ? "spinning-image" : ""} />
                        </div>
                    ))}
                </div>
            </div>
            
            {/* Winner pop-up with close button */}
            {winner && (
                <div className="winner-popup">
                    <button className="close-btn" onClick={() => setWinner(false)}>✖</button>
                    <img src="/images/tom.jpg" alt="Winner" />
                </div>
            )}

            {/* Confetti Animation */}
            {showConfetti && (
                <div className="confetti-container">
                    {[...Array(100)].map((_, i) => (
                        <div key={i} className="confetti" style={{ "--random": Math.random() }} />
                    ))}
                </div>
            )}

            {message && <div className="message">{message}</div>}
        </div>
    );
};

export default Valentines;
