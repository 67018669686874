import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; // ✅ Use Switch instead of Routes
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Loader from "./Components/Loader";
import Contact from "./Components/Contact";
import Modal from "./Components/Modal";
import Valentines from "./Components/Valentines"; // ✅ Import Valentine component

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeData: {},
      isLoading: true,
      show: false
    };

    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }

  componentDidMount() {
    this.getResumeData();
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 3000);
  }

  getResumeData() {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: (data) => this.setState({ resumeData: data }),
      error: (xhr, status, err) => console.log(err),
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => (
            <div>
              {this.state.isLoading ? <Loader /> : null}
              <Modal show={this.state.show}>
                <p>Modal</p>
              </Modal>
              <Header data={this.state.resumeData.main} />
              <About data={this.state.resumeData.main} />
              <Resume data={this.state.resumeData.resume} />
              <Contact data={this.state.resumeData.main} />
              <Footer data={this.state.resumeData.main} />
            </div>
          )} />
          <Route path="/valentines" component={Valentines} /> {/* ✅ Fixes the routing */}
        </Switch>
      </Router>
    );
  }
}

export default App;
